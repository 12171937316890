import { useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";

import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";

export default function BidTransfer({
  userId,
  shipmentTeamId,
  shipmentId,
}: {
  userId: number;
  shipmentTeamId: number;
  shipmentId: number;
}) {
  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useSnackbar();

  const [opensModal, setOpensModal] = useState(false);

  const { data: userCompanyNameList } =
    ADMIN_BID_QUERY.useGetShipmentUserCompanyNameList({
      userId,
    });

  const {
    mutate: updateShipmentUserCompanyName,
    ResponseHandler: ResponseHandlerOfUpdateShipmentUserCompanyName,
  } = ADMIN_BID_QUERY.useShipmentUserCompanyNameUpdate();

  const [selectShipmentTeamId, setSelectShipmentTeamId] =
    useState(shipmentTeamId);

  const handleCompanyNameSelectChange = (value: string | number) => {
    const parsedNumberValue = Number(value);

    /** MUI Select의 type이 string도 리턴하고 있어 number로 변환 이때 예외 사항을 확인해서 에러 호출 */
    if (isNaN(parsedNumberValue)) {
      handleSnackbarOpen(`잘못된 회사/팀을 선택했습니다.`, "error");
      return;
    }

    setSelectShipmentTeamId(parsedNumberValue);
  };

  const handleCompanyNameUpdateButtonClick = () => {
    updateShipmentUserCompanyName(
      {
        teamId: selectShipmentTeamId,
        pathParams: {
          shipmentId,
        },
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("의뢰 이관을 완료했습니다.");
          setOpensModal(false);

          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: shipmentId,
            })
          );
        },

        onError: ({ response }) => {
          handleSnackbarOpen(
            `요청에 실패했습니다. error: ${response?.data.error}`,
            "error"
          );
        },
      }
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        className="sads"
        onClick={() => setOpensModal(true)}
      >
        의뢰 이관
      </Button>

      {userCompanyNameList && (
        <Dialog open={opensModal} onClose={() => setOpensModal(false)}>
          <DialogTitle>의뢰 이관</DialogTitle>

          <DialogContent
            sx={{
              display: "flex",
              width: "300px",
            }}
          >
            <FormControl variant="standard" sx={{ flexGrow: 1 }}>
              <Select
                value={selectShipmentTeamId}
                /** MUI Select는 string을 리턴하고 있어 number로 변환 이때 예외 사항을 확인해서 에러 호출 */
                onChange={(e) => {
                  handleCompanyNameSelectChange(e.target.value);
                }}
              >
                {userCompanyNameList.map((userCompanyName) => {
                  return (
                    <MenuItem
                      key={userCompanyName.id}
                      value={userCompanyName.id}
                    >
                      {`회사명: ${userCompanyName.companyName || "-"} / 팀명: ${
                        userCompanyName.teamName || "-"
                      }`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              className="sads"
              sx={{ flexGrow: 1 }}
              onClick={handleCompanyNameUpdateButtonClick}
            >
              이관
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {ResponseHandlerOfUpdateShipmentUserCompanyName}
    </>
  );
}
