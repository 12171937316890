import { Control, Controller } from "react-hook-form";

import TextField from "@sellernote/_shared/src/admin-ui/components/TextField";
import { REQUEST_PARTNER_EMAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";

export default function CommentForm({
  control,
}: {
  control: Control<REQUEST_PARTNER_EMAIL_REQ>;
}) {
  return (
    <Controller
      control={control}
      name={"comment"}
      render={({ field: { ref, ...field } }) => {
        return (
          <TextField
            {...field}
            multiline
            rows={4}
            label="코멘트"
            fullWidth
            size="small"
            inputRef={ref}
          />
        );
      }}
    />
  );
}
