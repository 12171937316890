import { useState } from "react";
import { FORWARDING_ACCOUNT_ATOMS } from "jotaiStates/account";

import { useResetAtom } from "jotai/utils";

const useHandleCreateModal = () => {
  const resetSelectedTeam = useResetAtom(
    FORWARDING_ACCOUNT_ATOMS.SELECTED_TEAM_LIST
  );

  const resetSelectedPartner = useResetAtom(
    FORWARDING_ACCOUNT_ATOMS.SELECTED_PARTNER_LIST
  );

  const [opensCreateModal, setOpensCreateModal] = useState(false);

  const handleCreateModalOpen = () => setOpensCreateModal(true);
  const handleModalClose = () => {
    resetSelectedTeam();
    resetSelectedPartner();
    setOpensCreateModal(false);
  };

  return {
    opensCreateModal,
    handleCreateModalOpen,
    handleModalClose,
  };
};

export default useHandleCreateModal;
