import { useCallback, useState } from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { useFormContext, useWatch } from "react-hook-form";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import AutoCompleteWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/AutoCompleteWithReactHookForm";
import RadioGroupWithReactHookForm, {
  RadioGroupWithReactHookFormOption,
} from "@sellernote/_shared/src/admin-ui/components/RadioGroupWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/TextFieldWithReactHookForm";
import { Port } from "@sellernote/_shared/src/types/common/common";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { BidTransportType } from "@sellernote/_shared/src/types/forwarding/bid";

import { getAutoCompleteValidate, getPortNameOptionList } from "../utils";

function Export({
  startAddress,
  syncZoneId,
  startTypeRadioGroupOption,
  endTypeRadioGroupOption,
  portList = [],
  bidDetail,
}: {
  startAddress: string | undefined;
  syncZoneId: (address?: string | null) => void;
  startTypeRadioGroupOption: RadioGroupWithReactHookFormOption<BidTransportType>[];
  endTypeRadioGroupOption: RadioGroupWithReactHookFormOption<BidTransportType>[];
  portList?: Port[];
  bidDetail: AdminBidDetail;
}) {
  const { control, setValue, clearErrors } = useFormContext();

  const [opensDaumPostcode, setOpensDaumPostcode] = useState(false);

  const startType = useWatch({ control, name: "startType" });

  const endType = useWatch({ control, name: "endType" });

  const {
    freightType,
    endType: shipmentEndType,
    startType: shipmentStartType,
    endPortId: shipmentEndPortId,
    endViaPortId: shipmentEndViaPortId,
    zoneId: shipmentZoneId,
    endAddress: shipmentEndAddress,
    startAddressDetail: shipmentStartAddressDetail,
    startAddress: shipmentStartAddress,
    startPortId: shipmentStartPortId,
    startViaPortId: shipmentStartViaPortId,
  } = bidDetail;

  /** 출발지 주소 입력 시 해당 주소가 가지고 있는 zoneId를 등록 */
  const TransportInfoRef = useCallback(
    (node) => {
      if (node) {
        syncZoneId(startAddress);
      }
    },
    [startAddress, syncZoneId]
  );

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}
      ref={TransportInfoRef}
    >
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        <Typography variant="subtitle1" fontWeight={700}>
          출발지
        </Typography>

        <RadioGroupWithReactHookForm
          name="startType"
          control={control}
          required={true}
          radioGroupOption={startTypeRadioGroupOption}
          handleEffectOnChange={(e) => {
            // 출발지 타입에 따라 필요한 값만 추출하기 위해 shouldDirty를 사용
            const isInlandShipment = shipmentStartType === "inland";

            if (e.target.value !== "inland") {
              setValue("startAddress", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("startViaPortId", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("startAddressDetail", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("zoneId", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("startPortId", shipmentStartPortId, {
                shouldDirty: isInlandShipment,
              });
              return;
            }

            if (e.target.value === "inland") {
              setValue("startPortId", null, { shouldDirty: isInlandShipment });
              setValue("startAddress", shipmentStartAddress, {
                shouldDirty: !isInlandShipment,
              });
              setValue("startViaPortId", shipmentStartViaPortId, {
                shouldDirty: !isInlandShipment,
              });
              setValue("zoneId", shipmentZoneId, {
                shouldDirty: !isInlandShipment,
              });
              setValue("startAddressDetail", shipmentStartAddressDetail, {
                shouldDirty: !isInlandShipment,
              });
              return;
            }
          }}
        />

        {startType === "inland" ? (
          <>
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}
            >
              <TextFieldWithReactHookForm
                name="startAddress"
                control={control}
                label="출발지 주소"
                placeholder="출발지 주소"
                required={true}
                InputProps={{
                  readOnly: true,
                  endAdornment: startAddress && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setValue("startAddress", "", { shouldDirty: true })
                        }
                      >
                        <CloseIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ flexGrow: 1 }}
              />

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpensDaumPostcode(!opensDaumPostcode)}
              >
                주소검색 {opensDaumPostcode && "닫기"}
              </Button>
            </Box>

            {opensDaumPostcode && (
              <DaumPostcodeEmbed
                showMoreHName={true}
                onComplete={({ address }) => {
                  setValue("startAddress", address, { shouldDirty: true });
                  clearErrors("startAddress");
                  setOpensDaumPostcode(false);
                }}
              />
            )}

            <TextFieldWithReactHookForm
              name="startAddressDetail"
              control={control}
              label="출발지 상세주소"
              placeholder="출발지 상세주소"
              required={true}
              defaultValue={shipmentStartAddressDetail}
            />

            <AutoCompleteWithReactHookForm
              name="startViaPortId"
              control={control}
              label="항구/공항 선택"
              options={getPortNameOptionList({
                transportType: startType,
                portList: portList,
                freightType,
                isExport: true,
                isEndPort: false,
              })}
              required={true}
              validate={{ isSelect: (value) => getAutoCompleteValidate(value) }}
            />
          </>
        ) : (
          <AutoCompleteWithReactHookForm
            name="startPortId"
            control={control}
            label="항구/공항 선택"
            options={getPortNameOptionList({
              transportType: startType,
              portList: portList,
              freightType,
              isExport: true,
              isEndPort: false,
            })}
            required={true}
            validate={{ isSelect: (value) => getAutoCompleteValidate(value) }}
          />
        )}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        <Typography variant="subtitle1" fontWeight={700}>
          도착지
        </Typography>

        <RadioGroupWithReactHookForm
          name="endType"
          control={control}
          required={true}
          radioGroupOption={endTypeRadioGroupOption}
          handleEffectOnChange={(e) => {
            // 도착지 타입에 따라 필요한 값만 추출하기 위해 shouldDirty를 사용
            const isInlandShipment = shipmentEndType === "inland";

            if (e.target.value !== "inland") {
              setValue("endAddress", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endViaPortId", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endPortId", shipmentEndPortId, {
                shouldDirty: isInlandShipment,
              });
              return;
            }

            if (e.target.value === "inland") {
              setValue("endPortId", null, { shouldDirty: isInlandShipment });
              setValue("endAddress", shipmentEndAddress, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endViaPortId", shipmentEndViaPortId, {
                shouldDirty: !isInlandShipment,
              });
              return;
            }
          }}
        />

        {endType === "inland" ? (
          <>
            <AutoCompleteWithReactHookForm
              name="endViaPortId"
              control={control}
              label="항구/공항 선택"
              options={getPortNameOptionList({
                transportType: endType,
                portList: portList,
                freightType,
                isExport: true,
                isEndPort: true,
              })}
              required={true}
              validate={{ isSelect: (value) => getAutoCompleteValidate(value) }}
            />

            <TextFieldWithReactHookForm
              name="endAddress"
              control={control}
              label="도착지 주소"
              placeholder="도착지 주소"
              required={true}
            />
          </>
        ) : (
          <AutoCompleteWithReactHookForm
            name="endPortId"
            control={control}
            label="항구/공항 선택"
            options={getPortNameOptionList({
              transportType: endType,
              portList: portList,
              freightType,
              isExport: true,
              isEndPort: true,
            })}
            required={true}
            validate={{ isSelect: (value) => getAutoCompleteValidate(value) }}
          />
        )}
      </Box>
    </Box>
  );
}

export default Export;
