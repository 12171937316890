import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { Button, CircularProgress, Grid } from "@mui/material";

import QuotationEtcFormList from "@sellernote/_shared/src/admin-ui/components/QuotationEtcFormList";
import QuotationFeeForm from "@sellernote/_shared/src/admin-ui/components/QuotationFeeForm";
import ItemInfoForRead from "@sellernote/_shared/src/admin-ui/containers/itemInfo/ItemInfoForRead";
import TemplateSearch from "@sellernote/_shared/src/admin-ui/containers/TemplateSearch";
import {
  GET_ADMIN_BID_APPLY_ITEMS_RES,
  GET_ADMIN_BID_DETAIL_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import { ADMIN_BID_QUOTATION_ANOTHER_COMMENT_PORT_ID } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import { Liner } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";

function BidQuotation({
  bidDetail,
  exchangeRateList,
  control,
  watch,
  reset,
  setValue,
  handleSubmit,
  errors,
  submitFunction,
  applyItems,
  linerList,
  quotationType,
  isLoading,
  showsPreviousItemPrice,
}: {
  bidDetail: GET_ADMIN_BID_DETAIL_RES;
  exchangeRateList: ExchangeRate[];
  control: Control<ApplyBidFormData>;
  watch: UseFormWatch<ApplyBidFormData>;
  reset: UseFormReset<ApplyBidFormData>;
  setValue: UseFormSetValue<ApplyBidFormData>;
  handleSubmit: UseFormHandleSubmit<ApplyBidFormData>;
  errors: FieldErrorsImpl<DeepRequired<ApplyBidFormData>>;
  submitFunction: () => void;
  applyItems: GET_ADMIN_BID_APPLY_ITEMS_RES;
  linerList: Liner[];
  quotationType: "change" | "confirm";
  isLoading: boolean;
  showsPreviousItemPrice?: boolean;
}) {
  const quotationTypeToKr = quotationType === "change" ? "수정" : "확정";

  const checkIsAnotherCommentPort = (portId: number) => {
    return ADMIN_BID_QUOTATION_ANOTHER_COMMENT_PORT_ID.some(
      (port) => port.id === portId
    );
  };

  return (
    <Grid
      container
      gap={1}
      component="form"
      onSubmit={handleSubmit(submitFunction)}
    >
      {bidDetail && exchangeRateList && (
        <ItemInfoForRead
          bidDetail={bidDetail}
          exchangeRateList={exchangeRateList}
        />
      )}

      <TemplateSearch
        bidId={bidDetail.id}
        templateCategory={"all"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"all"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
      />

      <QuotationFeeForm
        bidId={bidDetail.id}
        quotationFeeFormCategory={"localCost"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"localFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"localFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
      />

      <QuotationFeeForm
        bidId={bidDetail.id}
        quotationFeeFormCategory={"freightCost"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"freightFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"freightFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
      />

      <QuotationFeeForm
        bidId={bidDetail.id}
        quotationFeeFormCategory={"domesticCost"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"domesticFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"domesticFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
      />

      <QuotationFeeForm
        bidId={bidDetail.id}
        quotationFeeFormCategory={"inlandCost"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"inlandFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"inlandFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
      />

      <QuotationFeeForm
        bidId={bidDetail.id}
        quotationFeeFormCategory={"otherCost"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"otherFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"otherFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
      />

      <QuotationFeeForm
        bidId={bidDetail.id}
        quotationFeeFormCategory={"tax"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"taxFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"taxFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
      />

      <QuotationEtcFormList
        control={control}
        errors={errors}
        linerList={linerList}
        freightType={bidDetail.freightType}
        showsAnotherCommentCheck={
          !!(
            bidDetail.endViaPortId &&
            bidDetail.zoneId &&
            quotationType === "confirm" &&
            // 인천, 부산, 인천신항, 부산신항만 접안항 코멘트가 존재
            checkIsAnotherCommentPort(bidDetail.endViaPortId)
          )
        }
      />

      <Grid
        item
        xs={12}
        container
        justifyContent={"center"}
        sx={{ marginTop: "32px" }}
      >
        <Grid item>
          <Button variant="contained" type="submit" disabled={isLoading}>
            {isLoading ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              quotationTypeToKr
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BidQuotation;
