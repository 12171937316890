import { Control } from "react-hook-form";

import TextFieldWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/TextFieldWithReactHookForm";
import { GET_PURCHASE_ORDER_PRODUCTS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import ProductNameList from "./ProductNameList";

export default function ItemName({
  control,
  formIndex,
  productsData,
  showsMultiItemName,
}: {
  control: Control<BidCreateFormData>;
  formIndex: number;
  productsData: GET_PURCHASE_ORDER_PRODUCTS_RES | undefined;
  showsMultiItemName: boolean | undefined;
}) {
  return showsMultiItemName ? (
    <ProductNameList formIndex={formIndex} productsData={productsData} />
  ) : (
    <TextFieldWithReactHookForm
      fullWidth
      name={`containersInfo.${formIndex}.name`}
      control={control}
    />
  );
}
