import { useQueryClient } from "react-query";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { sendRequest } from "@sellernote/_shared/src/services/request";
import { QuotationsUser } from "@sellernote/_shared/src/types/forwarding/bid";

import { useBidPartnerContext } from "../_hooks/useBidPartnerContext";

export default function FreightPaymentType({
  canEdit,
  quotationsUser,
  bidId,
}: {
  canEdit: boolean;
  quotationsUser: QuotationsUser[];
  bidId: number;
}) {
  const queryClient = useQueryClient();

  const { handleSnackbarOpen } = useBidPartnerContext();

  const getFreightPaymentType = () => {
    if (quotationsUser !== null) {
      if (quotationsUser[0].freightPaymentType === "pp") {
        return "pp";
      }
      if (quotationsUser[0].freightPaymentType === "cc") {
        return "cc";
      }
      return undefined;
    }
    return undefined;
  };

  const updateFreightPaymentType = async (e: SelectChangeEvent<string>) => {
    try {
      await sendRequest({
        method: "put",
        path: `/bid/${bidId}/quotationUser/${quotationsUser[0].id}`,
        apiType: "SHIPDA_ADMIN_API",
        data: {
          quotationData: {
            freightPaymentType: e.target.value,
          },
        },
      });

      handleSnackbarOpen("요청에 성공했습니다.");
      queryClient.invalidateQueries(
        ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
          bidId,
        })
      );
    } catch (err) {
      handleSnackbarOpen("요청에 실패했습니다.", "error");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "4px",
        flex: "0 0 auto",
        minWidth: "200px",
      }}
    >
      <Typography variant="body2" component="div">
        지급유형:
      </Typography>

      <FormControl sx={{ width: 150 }} size="small">
        <Select
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          }}
          disabled={!canEdit}
          defaultValue={getFreightPaymentType()}
          onChange={(e) => updateFreightPaymentType(e)}
        >
          <MenuItem value="pp">P.P</MenuItem>
          <MenuItem value="cc">C.C</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
