import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";

import BackTop from "@sellernote/_shared/src/admin-ui/components/BackTop";
import CargoInfo from "@sellernote/_shared/src/admin-ui/components/CargoInfo";
import CommentList from "@sellernote/_shared/src/admin-ui/components/CommentList";
import Tabs from "@sellernote/_shared/src/admin-ui/components/Tabs";
import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import BasicDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/BasicDesc";
import CheckDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/CheckDesc";
import ExporterDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/ExporterDesc";
import QuotationsUser from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/QuotationsUser";
import ScheduleDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/ScheduleDesc";
import TransportDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/TransportDesc";
import UserDesc from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/UserDesc";
import UploadDocuments from "@sellernote/_shared/src/admin-ui/pageContainers/trello/_components/detail/UploadDocuments";
import useShipmentCommentList from "@sellernote/_shared/src/hooks/admin/useShipmentCommentList";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import { FORWARDING_ADMIN_BID_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBid";

import Loading from "components/Loading";

import DraftQuotation from "./_components/DraftQuotation";
import { BidPartnerProvider } from "./_hooks/useBidPartnerContext";
import BidCopyModal from "./BidCopyModal";
import BidPartner from "./BidPartner";
import BidTransfer from "./BidTransfer";
import PartnerQuote from "./PartnerQuote";
import UpdateShipmentInfo from "./UpdateShipmentInfo";

const boxLayoutStyle: SxProps<Theme> = {
  p: "16px",
  borderRadius: "4px",
  background: "#fff",
  border: "1px solid #e0e0e0",
  display: "flex",
  flexDirection: "column",
  rowGap: "8px",
};

const BidDetail = () => {
  const [tabDefaultActiveKey, setTabDefaultActiveKey] = useRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.ADMIN_BID_DETAIL_TAB_DEFAULT_ACTIVE_KEY
  );

  const [showsBidCopyModal, setShowsBidCopyModal] = useState(false);

  const { id }: { id: string } = useParams();

  const { data: bidDetail } = ADMIN_BID_QUERY.useGetAdminBidDetail({
    bidId: Number(id),
  });

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: bidDetail?.locale,
  });

  const {
    handleCommentInputChange,
    handleCommentUpdate,
    comment,
    shipmentCommentList,
    handleCommentEdit,
    handleCommentDelete,
    ResponseHandler: ResponseHandlerOfShipmentCommentList,
  } = useShipmentCommentList({ shipmentId: Number(id) });

  /**
   * 수출자/수입자 정보 유무
   */
  const hasExporterInfo: boolean = useMemo(() => {
    if (!bidDetail?.itemGroupList?.length) {
      return false;
    }

    return bidDetail.itemGroupList.every(({ item: { exporter } }) => {
      if (!exporter) {
        return false;
      }

      // exporter 정보가 빈 string으로 초기화되어있으므로 전체 값의 유무를 체크한다.
      return (
        exporter.companyName &&
        exporter.personName &&
        exporter.personPhone &&
        exporter.personEmail
      );
    });
  }, [bidDetail?.itemGroupList]);

  // 견적지원 시 변경한 tab key를 원래대로 돌려놓는다(기본 0).
  useEffect(() => {
    return () => {
      setTabDefaultActiveKey(0);
    };
  }, [setTabDefaultActiveKey]);

  if (!bidDetail || !exchangeRateList) {
    return (
      <Layout title="의뢰 상세 정보">
        <Loading></Loading>
      </Layout>
    );
  }

  return (
    <Layout title="의뢰 상세 정보">
      <BackTop />

      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "12px",
            marginTop: "16px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "end", columnGap: "32px" }}>
            <BasicDesc bidDetail={bidDetail} />

            <Box sx={{ display: "flex", columnGap: "8px" }}>
              <BidTransfer
                userId={bidDetail.userId}
                shipmentId={bidDetail.id}
                shipmentTeamId={bidDetail.team.id}
              />

              <Button
                variant="contained"
                className="sads"
                onClick={() => setShowsBidCopyModal(true)}
              >
                의뢰복사
              </Button>
            </Box>
          </Box>

          <Box sx={boxLayoutStyle}>
            <Typography variant="h6" className="sads">
              사용자 정보
            </Typography>

            <UserDesc bidDetail={bidDetail} />
          </Box>

          <Box sx={boxLayoutStyle}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" className="sads">
                구간 정보
              </Typography>

              <UpdateShipmentInfo bidDetail={bidDetail} />
            </Box>

            <TransportDesc bidDetail={bidDetail} />

            <Divider sx={{ my: "16px" }} />

            <Typography variant="h6" className="sads">
              확인 사항
            </Typography>

            <CheckDesc bidDetail={bidDetail} />
          </Box>
        </Box>

        {bidDetail.serviceType !== "consolidation" && (
          <Box sx={boxLayoutStyle}>
            <ExporterDesc bidDetail={bidDetail}></ExporterDesc>
          </Box>
        )}

        <Box sx={boxLayoutStyle} position="relative">
          <Typography variant="h6">
            {bidDetail.serviceType === "consolidation" && "수출자 및 "}화물 정보
          </Typography>

          <CargoInfo bidDetail={bidDetail} />
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography variant="h6">
            {bidDetail.serviceType === "consolidation" && "수출자 및 "}서류 정보
          </Typography>

          <UploadDocuments trelloDetail={bidDetail} />
        </Box>

        <Box sx={boxLayoutStyle}>
          <ScheduleDesc bidDetail={bidDetail} />
        </Box>

        {bidDetail.quotationsUser.length > 0 && bidDetail.accountPayables && (
          <>
            <Box sx={boxLayoutStyle}>
              <BidPartnerProvider bidDetail={bidDetail}>
                <BidPartner
                  bidDetail={bidDetail}
                  hasExporterInfo={hasExporterInfo}
                />
              </BidPartnerProvider>
            </Box>
          </>
        )}

        <Box sx={boxLayoutStyle}>
          <PartnerQuote bidDetail={bidDetail} />
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            columnGap: "8px",
          }}
        >
          <Box sx={boxLayoutStyle}>
            <CommentList
              commentDataList={bidDetail.management.comments}
              commentPageType={"AutoShipment"}
            />
          </Box>

          <Box sx={boxLayoutStyle}>
            <CommentList
              comment={comment}
              onCommentInputChange={handleCommentInputChange}
              onCommentUpdate={handleCommentUpdate}
              commentDataList={shipmentCommentList}
              commentPageType="ManualShipment"
              onCommentEdit={handleCommentEdit}
              onCommentDelete={handleCommentDelete}
            />
          </Box>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Tabs
            defaultTabPanelValue={tabDefaultActiveKey}
            tabsData={[
              {
                tabLabel: "견적서 초안",
                tabPanelItem: <DraftQuotation shipmentDetailData={bidDetail} />,
              },

              {
                tabLabel: "확정 견적",
                tabPanelItem: bidDetail.quotationsUser.length > 0 && (
                  <QuotationsUser shipmentDetail={bidDetail} />
                ),
              },
            ]}
          />
        </Box>
      </Box>

      {showsBidCopyModal && (
        <BidCopyModal
          showsBidCopyModal={showsBidCopyModal}
          setShowsBidCopyModal={setShowsBidCopyModal}
          bidDetail={bidDetail}
          hasExporterInfo={hasExporterInfo}
        />
      )}

      {ResponseHandlerOfShipmentCommentList}
    </Layout>
  );
};

export default BidDetail;
