import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Grid, Typography } from "@mui/material";

import RadioGroupWithReactHookForm, {
  RadioGroupWithReactHookFormOption,
} from "@sellernote/_shared/src/admin-ui/components/RadioGroupWithReactHookForm";
import SelectWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/SelectWithReactHookForm";
import { ADMIN_BID_FREIGHT_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import { FreightType, Port } from "@sellernote/_shared/src/types/common/common";
import {
  BidCreateFormData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import useCheckBlankInFormData from "./hooks/useCheckBlankInFormData";
import useCreateBidButton from "./hooks/useCreateBidButton";
import useCreateExportButton from "./hooks/useCreateExportButton";
import useIsOceanTicket from "./hooks/useIsOceanTicket";
import useMoveToQuotationButton from "./hooks/useMoveToQuotationButton";

import BusinessAgency from "./BusinessAgency";
import CargoInfo from "./CargoInfo";
import CheckPoint from "./CheckPoint";
import IncotermsForm from "./IncotermsForm";
import InvoicePrice from "./InvoicePrice";
import TransportSection from "./TransportSection";

const radioGroupOptions: RadioGroupWithReactHookFormOption<
  "shipda" | "customer"
>[] = [
  {
    label: "쉽다",
    value: "shipda",
  },
  {
    label: "고객",
    value: "customer",
  },
];

const importOrExporterRadioGroupOptions: RadioGroupWithReactHookFormOption<
  "import" | "export"
>[] = [
  {
    label: "수입",
    value: "import",
  },
  {
    label: "수출",
    value: "export",
  },
];

const freightTypeRadioGroupOptions =
  ADMIN_BID_FREIGHT_TYPE_OPTION_LIST as RadioGroupWithReactHookFormOption<FreightType>[];

function BidCreateForm({
  portList,
  bidCreateFormData,
  formIndex,
  hasPO,
  POId,
  handleSessionStorageShipmentCreateInfoChange,
  sessionStorageShipmentCreateInfo,
  sessionStorageShipmentCreateUserInfo,
}: {
  portList: Port[];
  bidCreateFormData: BidCreateFormData;
  formIndex: number;
  hasPO: boolean;
  POId?: number;
  handleSessionStorageShipmentCreateInfoChange: (
    value: BidCreateStorageData
  ) => void;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
  sessionStorageShipmentCreateUserInfo: BidCreateUserData;
}) {
  const methods = useForm<BidCreateFormData>();

  const { control, watch, reset, handleSubmit, setValue } = methods;

  // react-hook-form에서 reset를 사용할 때 useEffect 사용을 권장함
  useEffect(() => {
    reset(bidCreateFormData);
  }, [bidCreateFormData, reset]);

  const {
    freightType,
    startType,
    endType,
    importCarrier,
    hopeCargoInsurance,
    bidType,
  } = watch();

  const importCustoms = watch("importCustoms");

  const { checkBlankInBidCreateForm } = useCheckBlankInFormData({
    sessionStorageShipmentCreateInfo,
  });

  const { isOceanticket } = useIsOceanTicket({
    portList,
    watch,
  });

  const {
    CreateBidButton,
    handleBidCreate,
    ResponseHandler: ResponseHandlerOfCreateBid,
  } = useCreateBidButton({
    formData: watch(),
    portList,
    isOceanticket,
    freightType,
    checkBlankInBidCreateForm,
    hasPO,
    POId,
    formIndex,
    handleSessionStorageShipmentCreateInfoChange,
    sessionStorageShipmentCreateInfo,
    sessionStorageShipmentCreateUserInfo,
  });

  const {
    CreateExportBidButton,
    handleExportBidCreate,
    ResponseHandler: ResponseHandlerOfCreateExportBid,
  } = useCreateExportButton({
    formData: watch(),
    portList,
    freightType,
    checkBlankInBidCreateForm,
    sessionStorageShipmentCreateInfo,
    sessionStorageShipmentCreateUserInfo,
  });

  const { MoveToQuotationButton } = useMoveToQuotationButton({
    bidType,
    formData: watch(),
    portList,
    isOceanticket,
    freightType,
    checkBlankInBidCreateForm,
    formIndex,
    handleSessionStorageShipmentCreateInfoChange,
    sessionStorageShipmentCreateInfo,
    sessionStorageShipmentCreateUserInfo,
  });

  /** 복수의 탭이 있는 경우 제거해주는 함수 */
  const handleTabPanelDelete = useCallback(() => {
    const newShipmentCreateFormData =
      sessionStorageShipmentCreateInfo.bidCreateData
        .filter((_, index) => {
          return index !== formIndex;
        })
        .map((v, index) => {
          return {
            ...v,
            // 인덱스는 0부터 시작이지만 뷰에서는 1 부터 시작이라서 1을 추가
            tabLabel: index + 1,
          };
        });

    const newShipmentCreateData = {
      bidCreateData: newShipmentCreateFormData,
      POId,
      hasPO,
      tabsDefaultValue: sessionStorageShipmentCreateInfo.tabsDefaultValue,
    };

    handleSessionStorageShipmentCreateInfoChange(newShipmentCreateData);
  }, [
    sessionStorageShipmentCreateInfo.bidCreateData,
    sessionStorageShipmentCreateInfo.tabsDefaultValue,
    POId,
    hasPO,
    handleSessionStorageShipmentCreateInfoChange,
    formIndex,
  ]);

  return (
    <FormProvider {...methods}>
      <Grid
        container
        direction={"column"}
        spacing={1}
        component="form"
        onSubmit={handleSubmit(
          bidType === "export" ? handleExportBidCreate : handleBidCreate
        )}
      >
        <Grid item container spacing={3} alignItems="center">
          <Grid item>
            <Typography variant="subtitle1" component="span">
              화주명
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2">
              {sessionStorageShipmentCreateUserInfo.userName}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={3} alignItems="center" xs={12}>
          <Grid item xs={1}>
            <Typography variant="subtitle1" component="span">
              수출입 구분
            </Typography>
          </Grid>

          <Grid item>
            <RadioGroupWithReactHookForm
              name="bidType"
              control={control}
              defaultValue="import"
              radioGroupOption={importOrExporterRadioGroupOptions}
            />
          </Grid>
        </Grid>

        {bidType === "import" && (
          <Grid item container spacing={3} alignItems="center" xs={12}>
            <Grid item xs={1}>
              <Typography variant="subtitle1" component="span">
                수입운송
              </Typography>
            </Grid>

            <Grid item>
              <RadioGroupWithReactHookForm
                name="importCarrier"
                control={control}
                defaultValue="shipda"
                radioGroupOption={radioGroupOptions}
              />
            </Grid>
          </Grid>
        )}

        {bidType === "export" && (
          <Grid item container spacing={3} alignItems="center" xs={12}>
            <Grid item xs={1}>
              <Typography variant="subtitle1" component="span">
                수출자 수
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1" component="span">
                {"1 (차후 수출자 수 옵션 추가 예정)"}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid item container spacing={3} alignItems="center" xs={12}>
          <Grid item xs={1}>
            <Typography variant="subtitle1" component="span">
              운송 유형
            </Typography>
          </Grid>

          <Grid item>
            <RadioGroupWithReactHookForm
              name="freightType"
              control={control}
              defaultValue="FCL"
              radioGroupOption={freightTypeRadioGroupOptions}
              // 운송 유형에 따라 출발지, 도착지 값을 변경
              handleEffectOnChange={(event) => {
                if (event.target.value === "AIR") {
                  setValue("startType", "air");
                  setValue("endType", "air");
                  return;
                }

                if (event.target.value !== "AIR") {
                  setValue("startType", "sea");
                  setValue("endType", "sea");
                  return;
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid item container alignItems="center" xs={12}>
          <Grid item xs={1}>
            <Typography variant="subtitle1" component="span">
              서비스 유형
            </Typography>
          </Grid>

          <Grid item>
            <SelectWithReactHookForm
              label="서비스 유형"
              name="serviceType"
              control={control}
              // 현재는 일반 의뢰만 차후 특송 추가 예정
              options={["일반"]}
              defaultValue="일반"
              sx={{ width: "200px" }}
            />
          </Grid>
        </Grid>

        {portList && <TransportSection portList={portList} />}

        <CargoInfo
          POId={POId}
          sessionStorageShipmentCreateInfo={sessionStorageShipmentCreateInfo}
        />

        <InvoicePrice />

        <BusinessAgency />

        <IncotermsForm />

        <CheckPoint isOceanticket={isOceanticket} />

        {bidType === "import" && (
          <Grid item container spacing={3}>
            <Grid item>{CreateBidButton}</Grid>

            <Grid item>{MoveToQuotationButton}</Grid>
          </Grid>
        )}

        {bidType === "export" && (
          <Grid item container spacing={3}>
            <Grid item>{CreateExportBidButton}</Grid>

            <Grid item>{MoveToQuotationButton}</Grid>
          </Grid>
        )}

        {sessionStorageShipmentCreateInfo.bidCreateData.length > 1 && (
          <Grid item>
            <Button onClick={handleTabPanelDelete}>삭제</Button>
          </Grid>
        )}
      </Grid>

      {ResponseHandlerOfCreateBid}
      {ResponseHandlerOfCreateExportBid}
    </FormProvider>
  );
}
export default BidCreateForm;
