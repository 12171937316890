import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";

import AutoCompleteWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/AutoCompleteWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/TextFieldWithReactHookForm";
import { CURRENCY_CODE_LIST } from "@sellernote/_shared/src/constants/common/common";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

function BusinessAgency() {
  const { control, setValue } = useFormContext<BidCreateFormData>();

  return (
    <Grid item container xs={12} alignItems="flex-end" spacing={1}>
      <Grid item xs={1}>
        <Typography variant="subtitle1" component="span">
          인보이스 금액
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <AutoCompleteWithReactHookForm
          label="통화"
          name={"invoicePrice.currency"}
          control={control}
          options={CURRENCY_CODE_LIST}
          sx={{ maxHeight: 200 }}
          ListboxProps={{
            style: {
              maxHeight: "200px",
            },
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <TextFieldWithReactHookForm
          name={"invoicePrice.foreignPrice"}
          control={control}
          placeholder="외화 대금"
          isOnlyPositiveNumber={true}
        />
      </Grid>
    </Grid>
  );
}

export default BusinessAgency;
