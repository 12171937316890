import { useAtom } from "jotai";
import { FORWARDING_ACCOUNT_ATOMS } from "jotaiStates/account";

const useHandleAccountTablePagination = () => {
  const [filter, setFilter] = useAtom(
    FORWARDING_ACCOUNT_ATOMS.ACCOUNT_TABLE_FILTER
  );

  const changePerPage = (value: number) => {
    setFilter({
      ...filter,
      perPage: value,
    });
  };

  const changePage = (value: number) => {
    setFilter({
      ...filter,
      page: value,
    });
  };

  return {
    currentPage: filter.page,
    perPage: filter.perPage,
    changePerPage,
    changePage,
  };
};

export default useHandleAccountTablePagination;
