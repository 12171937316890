import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";
import { FORWARDING_ACCOUNT_ATOMS } from "jotaiStates/account";

import { CREATE_ACCOUNT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminAccount";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_ACCOUNT_QUERY, {
  ADMIN_ACCOUNT_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ACCOUNT_QUERY";
import { AccountDetailInfo } from "@sellernote/_shared/src/types/forwarding/adminAccount";

import { AccountCategoryState, UpdateAccountDetailProps } from "../types";
import { getCategory, validateUpdateAccount } from "../utils";

import useGetAccountParams from "./useGetAccountParams";

const useUpdateAccountDetail = ({
  detailData,
  newAccountForm,
  category,
}: {
  detailData: AccountDetailInfo | undefined;
  newAccountForm: CREATE_ACCOUNT_REQ;
  category: AccountCategoryState;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const {
    mutate: updateDetail,
    ResponseHandler: ResponseHandlerOfUpdateAccountDetail,
  } = ADMIN_ACCOUNT_QUERY.useUpdateAccountDetail();

  const { params } = useGetAccountParams();

  const mappedTeamList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.MAPPED_TEAM_LIST
  );

  const mappedPartnerList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.MAPPED_PARTNER_LIST
  );

  const mappedTeamIdList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.MAPPED_TEAM_ID_LIST
  );

  const mappedPartnerIdList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.MAPPED_PARTNER_ID_LIST
  );

  const handleDetailUpdate = useCallback(
    ({ id }: UpdateAccountDetailProps) => {
      const categoryForm = getCategory(category);

      if (!mappedTeamList.length && !mappedPartnerList.length) {
        handleSnackbarOpen(
          "최소 하나의 팀 혹은 파트너를 선택해주세요.",
          "warning"
        );
        return;
      }

      const isValidForm = validateUpdateAccount({
        detailData,
        newAccountForm,
        category,
        onSnackbarOpen: handleSnackbarOpen,
      });

      /** 유효성 검사에 실패했을 때 */
      if (!isValidForm) return;

      updateDetail(
        {
          ...newAccountForm,
          ...categoryForm,
          teams: mappedTeamIdList,
          partners: mappedPartnerIdList,
          pathParams: { id },
        },
        {
          onSuccess: () => {
            handleSnackbarOpen("거래처 정보를 성공적으로 수정했습니다.");

            return Promise.all([
              queryClient.invalidateQueries(
                ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountList(params)
              ),

              queryClient.invalidateQueries(
                ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountDetail({
                  id,
                })
              ),

              queryClient.invalidateQueries(
                ADMIN_ACCOUNT_QUERY_KEY_GEN.getMappedAccount({
                  id,
                })
              ),
            ]);
          },
        }
      );
    },
    [
      category,
      detailData,
      handleSnackbarOpen,
      mappedPartnerIdList,
      mappedPartnerList.length,
      mappedTeamIdList,
      mappedTeamList.length,
      newAccountForm,
      params,
      queryClient,
      updateDetail,
    ]
  );

  return { handleDetailUpdate, ResponseHandlerOfUpdateAccountDetail };
};

export default useUpdateAccountDetail;
