import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Box, Button, colors, Grid, Typography } from "@mui/material";

import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared/src/admin-ui/components/SADSTable";
import TextFieldWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/TextFieldWithReactHookForm";
import { REQUEST_PARTNER_EMAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import {
  AdminBidPartnerQuoteConsolidationItem,
  ConsolidationItemList,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getConsolidationItemListForImport } from "@sellernote/_shared/src/utils/forwarding/bid";

import { useBidPartnerContext } from "../../../../_hooks/useBidPartnerContext";
import CommentForm from "../_components/CommentForm";
import CompanyNameForm from "../_components/CompanyNameForm";
import MailCommentModal from "../_components/MailCommentModal";

type CellKey = "품명" | "품명(영문)";

function ConsolidationsItemEmailForm() {
  const queryClient = useQueryClient();

  const {
    partnerBusinessArea,
    bidAccountPayableId,
    language,
    bidDetail,
    showsMailCommentModal,
    setShowsMailCommentModal,
    setShowsRequestEmailToPartner,
    partnerName,
    handleSnackbarOpen,
  } = useBidPartnerContext();

  const sellerList = getConsolidationItemListForImport(bidDetail.itemGroupList);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<REQUEST_PARTNER_EMAIL_REQ>({
    defaultValues: {
      bidId: bidDetail.id,
      engCompany: bidDetail.user.engCompany,
      company: bidDetail.user.company,
      consolidationFreightInfo: sellerList.map((seller) => {
        const result: AdminBidPartnerQuoteConsolidationItem[] =
          seller.itemList.map((v) => {
            const subResult: AdminBidPartnerQuoteConsolidationItem = {
              item: {
                itemName: v.name,
                itemNameEn: v.engName || "",
                address: v.address || "",
                volumeUnit: v.volumeUnit,
                height: v.height,
                vertical: v.vertical,
                horizontal: v.horizontal,
                quantity: v.quantity,
                weight: v.weight,
                weightUnit: v.weightUnit,
                cbm: v.cbm,
                packagingType: v.packingType,
                exporter: v.exporter,
              },
            };

            return subResult;
          });

        return result;
      }),
      comment: "",
      partnerManagerType: partnerBusinessArea,
    },
  });

  const {
    mutate: requestPartnerEmail,
    ResponseHandler: ResponseHandlerOfRequestPartnerEmail,
  } = ADMIN_BID_QUERY.useRequestPartnerEmail();

  const handlePartnerQuoteRequest = () => {
    requestPartnerEmail(
      { ...watch(), bidAccountPayableId },
      {
        onSuccess: ({ data }) => {
          if (data) {
            setShowsMailCommentModal(true);
            handleSnackbarOpen("요청에 성공했습니다.");
            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: bidDetail.id,
              })
            );
            return;
          }

          handleSnackbarOpen("요청에 성공했습니다.");
          setShowsRequestEmailToPartner(false);
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const headCellList: TableHeadCell<CellKey>[] = [
    { cellKey: "품명", label: "품명", width: 300 },
    {
      cellKey: "품명(영문)",
      label: "품명(영문)",
      width: 300,
      hidden: language !== "en",
    },
  ];

  const createBodyRowList = ({
    sellerData,
    consolidationItemsIndex,
  }: {
    sellerData: ConsolidationItemList;
    consolidationItemsIndex: number;
  }): TableBodyRow<CellKey>[] => {
    return sellerData.itemList.map((item, itemIndex) => {
      return {
        rowKey: item.id,
        품명: {
          value: (
            <Grid container>
              <TextFieldWithReactHookForm
                name={`consolidationFreightInfo.${consolidationItemsIndex}.${itemIndex}.item.itemName`}
                control={control}
                fullWidth
                error={
                  errors.consolidationFreightInfo &&
                  errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                    itemIndex
                  ]?.item?.itemName?.type === "required"
                }
                helperText={
                  errors.consolidationFreightInfo &&
                  errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                    itemIndex
                  ]?.item?.itemName?.type === "required" &&
                  "필수 입력입니다."
                }
              />
            </Grid>
          ),
        },
        "품명(영문)": {
          value: (
            <Grid container>
              {language === "en" ? (
                <TextFieldWithReactHookForm
                  name={`consolidationFreightInfo.${consolidationItemsIndex}.${itemIndex}.item.itemNameEn`}
                  control={control}
                  fullWidth
                  required={true}
                  error={
                    errors.consolidationFreightInfo &&
                    errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                      itemIndex
                    ]?.item?.itemNameEn?.type === "required"
                  }
                  helperText={
                    errors.consolidationFreightInfo &&
                    errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                      itemIndex
                    ]?.item?.itemNameEn?.type === "required" &&
                    "필수 입력입니다."
                  }
                />
              ) : null}
            </Grid>
          ),
        },
      };
    });
  };

  return (
    <Grid container spacing={4} direction="row" component="form">
      <Grid item>
        <Typography className="sads" variant="h6">
          파트너 - {partnerName} 컨택
        </Typography>
      </Grid>

      <Grid item width={"100%"}>
        <CompanyNameForm
          control={control}
          errors={errors}
          bidUser={bidDetail.user}
          partnerBusinessArea={partnerBusinessArea}
        />
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          화물
        </Typography>

        <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
          {sellerList?.map((sellerData, consolidationItemsIndex) => {
            const bodyRowList = createBodyRowList({
              sellerData,
              consolidationItemsIndex,
            });

            return (
              <Box key={consolidationItemsIndex}>
                <Typography
                  variant="subtitle1"
                  component="div"
                  color={colors.blue[600]}
                >
                  {`수출자${consolidationItemsIndex + 1}`}
                </Typography>

                <SADSTable
                  headCellList={headCellList}
                  bodyRowList={bodyRowList}
                />
              </Box>
            );
          })}
        </Box>
      </Grid>

      <Grid item width={"100%"}>
        <CommentForm control={control} />
      </Grid>

      <Grid item container justifyContent={"end"} gap={2}>
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            /** 현재 수출 특송은 없어서 수입으로만 설정 */
            onClick={handleSubmit(handlePartnerQuoteRequest)}
          >
            컨택 요청
          </Button>
        </Grid>
      </Grid>

      {showsMailCommentModal && <MailCommentModal />}
      {ResponseHandlerOfRequestPartnerEmail}
    </Grid>
  );
}

export default ConsolidationsItemEmailForm;
