import { useFieldArray, useFormContext } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid } from "@mui/material";

import AutoCompleteWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/AutoCompleteWithReactHookForm";
import NumericFormatWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/NumericFormatWithReactHookForm";
import { ADMIN_BID_CONTAINER_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { ContainerType } from "@sellernote/_shared/src/types/forwarding/bid";

export default function ContainerList({ formIndex }: { formIndex: number }) {
  const { control, watch, getValues } = useFormContext<BidCreateFormData>();

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: `containersInfo.${formIndex}.containerList`,
  });

  const handleContainerAdd = () => {
    /**
     * 아직 추가되지 않은 container type만 추가할 수 있다.
     * - 수량을 늘리는 식으로 사용되기 때문
     */
    const nextAddableContainerType = remainedContainerTypeList?.[0];
    if (!nextAddableContainerType) {
      return;
    }

    append({
      containerType: nextAddableContainerType,
      quantity: 0,
    });
  };

  const watchedContainerList = watch(
    `containersInfo.${formIndex}.containerList`
  );
  /**
   * 아직 추가되지 않은 containerType
   */
  const remainedContainerTypeList: ContainerType[] = (() => {
    return (
      ADMIN_BID_CONTAINER_TYPE_OPTION_LIST.filter(
        (option) =>
          // watch를 쓰지 않으면 option에 현재 값이 반영 안 되어 watch 상태를 사용함.
          !watchedContainerList?.some(
            (field) => field.containerType === option.value
          )
      )?.map((option) => option.value) || []
    );
  })();

  const getOptionDisabled = (option: ContainerType) => {
    return !remainedContainerTypeList.includes(option);
  };

  return (
    <Grid container spacing={1}>
      {fields.map((field, index) => {
        return (
          <Grid container item spacing={2} key={field.id}>
            <Grid item flex={2} alignItems={"center"}>
              <AutoCompleteWithReactHookForm
                name={`containersInfo.${formIndex}.containerList.${index}.containerType`}
                control={control}
                options={ADMIN_BID_CONTAINER_TYPE_OPTION_LIST.map((v) => {
                  return v.value;
                })}
                getOptionDisabled={getOptionDisabled}
                required={true}
              />
            </Grid>

            <Grid item>
              <CloseIcon fontSize="small" sx={{ marginTop: "4px" }} />
            </Grid>

            <Grid item flex={1}>
              <NumericFormatWithReactHookForm
                name={`containersInfo.${formIndex}.containerList.${index}.quantity`}
                control={control}
                required={true}
              />
            </Grid>

            <Grid item>
              <Button
                disabled={fields.length === 1}
                color="warning"
                size="small"
                onClick={() => remove(index)}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        );
      })}

      <Grid item>
        <Button
          variant="contained"
          color="warning"
          onClick={handleContainerAdd}
          size="small"
          endIcon={<AddCircleIcon />}
          disabled={!remainedContainerTypeList.length}
        >
          컨테이너 추가
        </Button>
      </Grid>
    </Grid>
  );
}
