import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import TextField from "@sellernote/_shared/src/admin-ui/components/TextField";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";

import { useBidPartnerContext } from "pages/bid/detail/:id/_hooks/useBidPartnerContext";

function MailCommentModal() {
  const { partnerName } = useBidPartnerContext();
  const [comment, setComment] = useState("");
  const [isPopupOff, setIsPopupOff] = useState(false);

  const {
    bidDetail,
    setShowsRequestEmailToPartner,
    showsMailCommentModal,
    setShowsMailCommentModal,
    handleSnackbarOpen,
  } = useBidPartnerContext();

  const {
    mutate: updateBidPartnerMailCommentRequest,
    ResponseHandler: ResponseHandlerOfUpdateBidPartnerMailComment,
  } = ADMIN_BID_QUERY.useUpdateBidPartnerMailComment();

  const handleBidPartnerMailCommentUpdate = (isSendEmailFlag: boolean) => {
    updateBidPartnerMailCommentRequest(
      {
        bidId: bidDetail.id,
        comment,
        popupOff: isPopupOff,
        sendEmailFlag: isSendEmailFlag,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");
          setShowsMailCommentModal(false);
          setShowsRequestEmailToPartner(false);
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return (
    <>
      <Modal
        isOpened={showsMailCommentModal}
        handleClose={() => setShowsMailCommentModal(false)}
        modalBody={
          <Grid container spacing={1} direction={"column"}>
            <Grid item>
              <Typography variant="h6">
                {partnerName}에게 부킹 메일을 발송했습니다.
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1">
                고객사에게 담당자 배정에 대한 자동 메일을 발송하시겠습니까?
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body2">
                (*추가하고 싶은 코멘트가 있으면 입력해주세요.)
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                placeholder="메모를 남겨주세요"
                sx={{
                  width: 400,
                }}
                multiline={true}
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                label="해당 사용자는 팝업 노출하지 않기"
                control={
                  <Checkbox
                    checked={isPopupOff}
                    onChange={(e) => setIsPopupOff(e.target.checked)}
                  />
                }
              />
            </Grid>

            <Grid item container justifyContent={"center"} spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    handleBidPartnerMailCommentUpdate(false);
                  }}
                >
                  아니오(미발송)
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleBidPartnerMailCommentUpdate(true);
                  }}
                >
                  예(발송)
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />

      {ResponseHandlerOfUpdateBidPartnerMailComment}
    </>
  );
}

export default MailCommentModal;
