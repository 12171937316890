import { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import ADMIN_ZONE_ADDRESS_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ZONE_ADDRESS_QUERY";
import {
  AdminBidDetail,
  AdminShipmentInfoForm,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";

import { transPortTypeRadioGroupOptionList } from "../constants";
import Consolidation from "./Consolidation";
import Export from "./Export";
import Import from "./Import";

function TransportInfo({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { control, setValue } = useFormContext<AdminShipmentInfoForm>();

  const endAddress = useWatch({
    control,
    name: "endAddress",
  });

  const startAddress = useWatch({
    control,
    name: "startAddress",
  });

  const incoterms = useWatch({ control, name: "incoterms" });

  const isCTypeIncoterms =
    incoterms === "CFR" ||
    incoterms === "CIF" ||
    incoterms === "CPT" ||
    incoterms === "CIP";

  const { isImport, freightType } = bidDetail;

  // 도착지 주소 입력시 API 중복 요청을 피하기 위해 입력 지연
  const debouncedEndAddress = useDebounce(endAddress, 500);
  const debouncedStartAddress = useDebounce(startAddress, 500);

  const { data: zoneAddress } = ADMIN_ZONE_ADDRESS_QUERY.useGetAdminZoneAddress(
    {
      address: isImport
        ? debouncedEndAddress ?? ""
        : debouncedStartAddress ?? "",
    }
  );

  /** 출발지 운송타입 */
  const startTypeRadioGroupOption = transPortTypeRadioGroupOptionList.map(
    (option) => {
      if (freightType === "AIR") {
        return {
          ...option,
          /** AIR일 때 해상은 disabled 만약 C 조건이라면 내륙도 disabled */
          disabled:
            option.value === "sea" ||
            (option.value === "inland" && isCTypeIncoterms && isImport),
        };
      }

      return {
        ...option,
        /** FCL, LCL 때 항공은 disabled 만약 C 조건이라면 내륙도 disabled */
        disabled:
          option.value === "air" ||
          (option.value === "inland" && isCTypeIncoterms && isImport),
      };
    }
  );

  /** 도착지 운송타입 */
  const endTypeRadioGroupOption = transPortTypeRadioGroupOptionList.map(
    (option) => {
      if (freightType === "AIR") {
        return {
          ...option,
          /** AIR일 때 해상은 disabled 만약 수입 C 조건이라면 내륙만 선택 가능 */
          disabled:
            option.value === "sea" ||
            (option.value === "air" && isCTypeIncoterms && isImport),
        };
      }

      return {
        ...option,
        /** FCL, LCL 때 항공은 disabled 만약 수입 C 조건이라면 내륙만 선택 가능 */
        disabled:
          option.value === "air" ||
          (option.value === "sea" && isCTypeIncoterms && isImport),
      };
    }
  );

  const syncZoneId = useCallback(
    (address?: string | null) => {
      setValue(
        "zoneId",
        zoneAddress?.find((v) => {
          return v.roadAddress === address || v.jibunAddress === address;
        })?.id || 0,
        { shouldDirty: true }
      );
    },
    [setValue, zoneAddress]
  );

  return bidDetail.serviceType === "consolidation" ? (
    <Consolidation
      syncZoneId={syncZoneId}
      endTypeRadioGroupOption={endTypeRadioGroupOption}
      portList={portList}
      bidDetail={bidDetail}
    />
  ) : isImport ? (
    <Import
      endAddress={endAddress}
      isCTypeIncoterms={isCTypeIncoterms}
      syncZoneId={syncZoneId}
      startTypeRadioGroupOption={startTypeRadioGroupOption}
      endTypeRadioGroupOption={endTypeRadioGroupOption}
      portList={portList}
      bidDetail={bidDetail}
    />
  ) : (
    <Export
      startAddress={startAddress}
      syncZoneId={syncZoneId}
      startTypeRadioGroupOption={startTypeRadioGroupOption}
      endTypeRadioGroupOption={endTypeRadioGroupOption}
      portList={portList}
      bidDetail={bidDetail}
    />
  );
}

export default TransportInfo;
