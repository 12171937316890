import { ChangeEvent, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";

import TextField from "@sellernote/_shared/src/admin-ui/components/TextField";
import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

const ChangeAdminUserPassword = () => {
  const history = useHistory();

  const { id }: { id: string } = useParams();

  const [password, setPassword] = useState("");

  const {
    mutate: updateAdminUserDetail,
    ResponseHandler: ResponseHandlerOfUpdateAdminUserDetail,
  } = ADMIN_USER_QUERY.useUpdateAdminUserDetail({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.push("/dashboard");
      },

      customizeMessage: () => ({
        title: "수정이 완료되었습니다.",
      }),
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "수정에 실패했습니다.",
      }),
    },
  });

  const handleAdminUserPasswordChange = useCallback(() => {
    updateAdminUserDetail({
      password,
      id: Number(id),
    });
  }, [id, password, updateAdminUserDetail]);

  const handlePasswordChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    []
  );

  return (
    <Layout breadcrumbs={["사용자 관리"]} title={"비밀번호 수정"}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <TextField
            label="비밀번호"
            sx={{ width: 400 }}
            type="password"
            onChange={handlePasswordChange}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" onClick={handleAdminUserPasswordChange}>
            수정
          </Button>
        </Grid>
      </Grid>

      {ResponseHandlerOfUpdateAdminUserDetail}
    </Layout>
  );
};

export default ChangeAdminUserPassword;
