import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import QuotationUserMailModal from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/components/QuotationUserMailModal";
import {
  GET_ADMIN_BID_APPLY_ITEMS_RES,
  GET_ADMIN_BID_DETAIL_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import useGetNewCurrencyQuotation from "@sellernote/_shared/src/hooks/admin/useGetNewCurrencyQuotation";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { Liner } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getLinerId,
  getQuotationDataCurrency,
  replaceBrTagsWithNewline,
} from "@sellernote/_shared/src/utils/forwarding/admin/adminBid";
import {
  findExchangeRate,
  getAllTotalItemPriceOfFeeData,
} from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";

import BidQuotation from "pages/bid/_components/BidQuotation";
import { removePreviousItemPrice } from "./utils";

function UserQuotation({
  bidDetail,
  exchangeRateList,
  applyItems,
  linerList,
}: {
  bidDetail: GET_ADMIN_BID_DETAIL_RES;
  exchangeRateList: ExchangeRate[];
  applyItems: GET_ADMIN_BID_APPLY_ITEMS_RES;
  linerList: Liner[];
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [showsQuotationUserMailModal, setShowsQuotationUserMailModal] =
    useState(false);

  const {
    mutate: changeUserQuotation,
    isLoading,
    ResponseHandler: ResponseHandlerOfChangeUserQuotation,
  } = ADMIN_BID_QUERY.useChangeUserQuotation();

  const {
    mutate: changeUserExportQuotation,
    isLoading: isExportLoading,
    ResponseHandler: ResponseHandlerOfChangeUserExportQuotation,
  } = ADMIN_BID_QUERY.useChangeUserExportQuotation();

  const history = useHistory();

  const { quotationWithIsTaxExempt } = useGetNewCurrencyQuotation({
    bidDetail,
    exchangeRateList,
    type: "userQuotation",
    hasPreviousItemPrice: true,
  });

  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplyBidFormData>({
    defaultValues: {
      otherFeeData: quotationWithIsTaxExempt.otherFee || [],
      freightPaymentType: quotationWithIsTaxExempt.freightPaymentType,
      liner: quotationWithIsTaxExempt.liner,
      freightFeeData: quotationWithIsTaxExempt.freightFee || [],
      domesticFeeData: quotationWithIsTaxExempt.domesticFee || [],
      inlandFeeData: quotationWithIsTaxExempt.inlandFee || [],
      localFeeData: quotationWithIsTaxExempt.localFee || [],
      taxFeeData: quotationWithIsTaxExempt.taxFee || [],
      leadtime: quotationWithIsTaxExempt.leadtime,
      comment: replaceBrTagsWithNewline(quotationWithIsTaxExempt.comment),
      expiredAt: quotationWithIsTaxExempt.expiredAt,
      isTransit: quotationWithIsTaxExempt.isTransit,
    },
  });

  const handleQuotationUserUpdate = useCallback(
    (allowsSendmail: boolean) => {
      const requestPayload = {
        pathParams: {
          bidId: bidDetail.id,
          id: quotationWithIsTaxExempt.id,
        },

        quotationData: {
          currency: getQuotationDataCurrency({
            localFee: watch("localFeeData"),
            freightFee: watch("freightFeeData"),
            domesticFee: watch("domesticFeeData"),
            inlandFee: watch("inlandFeeData"),
            otherFee: watch("otherFeeData"),
            taxFee: watch("taxFeeData"),
            exchangeRateList: exchangeRateList,
          }),
          freightPaymentType: watch("freightPaymentType"),
          liner: watch("liner"),
          isTransit: watch("isTransit"),
          exchangeRate: findExchangeRate(exchangeRateList, "USD"),
          totalPrice: getAllTotalItemPriceOfFeeData(
            watch("freightFeeData"),
            watch("domesticFeeData"),
            watch("localFeeData"),
            watch("inlandFeeData"),
            watch("otherFeeData"),
            watch("taxFeeData")
          ),
          freightFee: removePreviousItemPrice({
            feeList: watch("freightFeeData"),
          }),
          localFee: removePreviousItemPrice({
            feeList: watch("localFeeData"),
          }),
          domesticFee: removePreviousItemPrice({
            feeList: watch("domesticFeeData"),
          }),
          inlandFee: removePreviousItemPrice({
            feeList: watch("inlandFeeData"),
          }),
          taxFee: removePreviousItemPrice({
            feeList: watch("taxFeeData"),
          }),
          otherFee: removePreviousItemPrice({
            feeList: watch("otherFeeData"),
          }),
          comment: watch("comment"),
          expiredAt: watch("expiredAt"),
          leadtime: watch("leadtime"),
          linerId: getLinerId(linerList, watch("liner")),
        },

        notiModifiedQuote: allowsSendmail,
      };

      if (bidDetail.isImport) {
        changeUserQuotation(requestPayload, {
          onSuccess: () => {
            history.replace(`/bid/detail/${bidDetail.id}`);
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        });

        return;
      }

      changeUserExportQuotation(requestPayload, {
        onSuccess: () => {
          history.replace(`/bid/detail/${bidDetail.id}`);
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      });
      return;
    },
    [
      bidDetail.id,
      bidDetail.isImport,
      changeUserExportQuotation,
      changeUserQuotation,
      exchangeRateList,
      handleSnackbarOpen,
      history,
      linerList,
      quotationWithIsTaxExempt.id,
      watch,
    ]
  );

  const handleQuotationUserMailModalOpen = () => [
    setShowsQuotationUserMailModal(true),
  ];

  return (
    <>
      <BidQuotation
        bidDetail={bidDetail}
        exchangeRateList={exchangeRateList}
        control={control}
        watch={watch}
        reset={reset}
        setValue={setValue}
        handleSubmit={handleSubmit}
        errors={errors}
        submitFunction={handleQuotationUserMailModalOpen}
        applyItems={applyItems}
        linerList={linerList}
        quotationType={"change"}
        isLoading={bidDetail.isImport ? isLoading : isExportLoading}
        showsPreviousItemPrice={true}
      />

      {showsQuotationUserMailModal && (
        <QuotationUserMailModal
          showsQuotationUserMailModal={showsQuotationUserMailModal}
          onQuotationUserMailModalClose={() =>
            setShowsQuotationUserMailModal(false)
          }
          onQuotationUserUpdate={handleQuotationUserUpdate}
        />
      )}

      {ResponseHandlerOfChangeUserQuotation}
      {ResponseHandlerOfChangeUserExportQuotation}
    </>
  );
}

export default UserQuotation;
