import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Grid, Typography } from "@mui/material";
import { useSetRecoilState } from "recoil";

import QuotationEtcFormList from "@sellernote/_shared/src/admin-ui/components/QuotationEtcFormList";
import QuotationFeeForm from "@sellernote/_shared/src/admin-ui/components/QuotationFeeForm";
import ItemInfoForBidCreate from "@sellernote/_shared/src/admin-ui/containers/itemInfo/ItemInfoForBidCreate";
import TemplateSearch from "@sellernote/_shared/src/admin-ui/containers/TemplateSearch";
import { GET_ADMIN_BID_APPLY_ITEMS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import { GET_FCL_ESTIMATE_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import {
  FreightType,
  Liner,
  Port,
} from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  BidCreateFormData,
  BidCreateQuotationStorageData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";

import useConfirmExportQuotationButton from "./hooks/useConfirmExportQuotationButton";
import useConfirmQuotationButton from "./hooks/useConfirmQuotationButton";
import useReturnToBidCrateButton from "./hooks/useReturnToBidCreateButton";

function QuotationForm({
  bidCreateFormData,
  exchangeRateList,
  applyItems,
  linerList,
  estimate,
  portList,
  isOceanticket,
  sessionStorageQuotationInfo,
  sessionStorageShipmentCreateUserInfo,
  handleSessionStorageShipmentCreateInfoChange,
  sessionStorageShipmentCreateInfo,
}: {
  bidCreateFormData: BidCreateFormData;
  exchangeRateList: ExchangeRate[] | undefined;
  applyItems: GET_ADMIN_BID_APPLY_ITEMS_RES | undefined;
  linerList: Liner[] | undefined;
  estimate: GET_FCL_ESTIMATE_RES | undefined;
  portList: Port[] | undefined;
  isOceanticket: boolean;
  sessionStorageQuotationInfo: BidCreateQuotationStorageData;
  sessionStorageShipmentCreateUserInfo: BidCreateUserData;
  handleSessionStorageShipmentCreateInfoChange: (
    value: BidCreateStorageData
  ) => void;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
}) {
  const setCargoInfoFormType = useSetRecoilState(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const { freightType }: { freightType: FreightType } = useParams();

  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplyBidFormData>({
    defaultValues: {
      freightFeeData: estimate?.freightFee ?? [],
      domesticFeeData: estimate?.domesticFee ?? [],
      localFeeData: estimate?.localFee ?? [],
      otherFeeData: estimate?.otherFee ?? [],
      inlandFeeData: estimate?.inlandFee ?? [],
      taxFeeData: estimate?.taxFee ?? [],
      expiredAt: estimate?.fare?.expiredAt
        ? new Date(estimate?.fare?.expiredAt)
        : new Date(),
      isTransit: estimate?.fare?.transitType
        ? estimate?.fare?.transitType === "t/s"
        : false,
      liner: estimate?.fare?.liner,
      freightPaymentType: estimate?.fare?.freightPaymentType
        ? estimate?.fare?.freightPaymentType
        : "cc",
      comment: "",
    },
  });

  /**
   * 의뢰상세에서 입력한 물품정보를 "packaging"으로 등록한 한 경우
   * cargoInfoFormType가 디폴트값인 totalVolume으로 변경돼서 다시 변경해줘야함
   */
  const quotationFormRefCallback = useCallback(() => {
    if (
      bidCreateFormData.freightType !== "FCL" &&
      bidCreateFormData?.productsInfo
    ) {
      if (bidCreateFormData?.productsInfo[0].height) {
        setCargoInfoFormType("packaging");
      }
    }
  }, [
    bidCreateFormData.freightType,
    bidCreateFormData?.productsInfo,
    setCargoInfoFormType,
  ]);

  const {
    ConfirmQuotationButton,
    handleQuotationConfirm,
    ResponseHandler: ResponseHandlerOfConfirmQuotation,
  } = useConfirmQuotationButton({
    portList,
    bidCreateFormData,
    isOceanticket,
    freightType: bidCreateFormData.freightType,
    watch,
    linerList,
    exchangeRateList,
    estimate,
    POId: sessionStorageQuotationInfo.POId,
    hasPO: sessionStorageQuotationInfo.hasPO,
    formIndex: sessionStorageQuotationInfo.tabsDefaultValue,
    sessionStorageQuotationInfo: sessionStorageQuotationInfo,
    sessionStorageShipmentCreateUserInfo,
    handleSessionStorageShipmentCreateInfoChange,
    sessionStorageShipmentCreateInfo,
  });

  const {
    ConfirmExportQuotationButton,
    handleExportQuotationConfirm,
    ResponseHandler: ResponseHandlerOfConfirmExportQuotation,
  } = useConfirmExportQuotationButton({
    portList,
    bidCreateFormData,
    freightType: bidCreateFormData.freightType,
    watch,
    linerList,
    exchangeRateList,
    estimate,
    hasPO: sessionStorageQuotationInfo.hasPO,
    formIndex: sessionStorageQuotationInfo.tabsDefaultValue,
    sessionStorageQuotationInfo,
    sessionStorageShipmentCreateUserInfo,
    handleSessionStorageShipmentCreateInfoChange,
    sessionStorageShipmentCreateInfo,
  });

  const { ReturnToBidCreateButton } = useReturnToBidCrateButton();

  return (
    <Grid
      ref={quotationFormRefCallback}
      container
      gap={1}
      component="form"
      onSubmit={handleSubmit(
        bidCreateFormData.bidType === "export"
          ? handleExportQuotationConfirm
          : handleQuotationConfirm
      )}
    >
      {exchangeRateList && portList && (
        <ItemInfoForBidCreate
          bidCreateFormData={bidCreateFormData}
          exchangeRateList={exchangeRateList}
          portList={portList}
        />
      )}

      <Grid item container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <ErrorOutlineIcon color="error" />
        </Grid>

        <Grid item>
          <Typography variant="subtitle1" color="error">
            {estimate
              ? "실시간 견적 구간이 존재합니다."
              : "실시간 견적 구간이 없습니다."}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container gap={1}>
        {exchangeRateList && (
          <TemplateSearch
            templateCategory={"all"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"all"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
          />
        )}

        {exchangeRateList && applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"localCost"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"localFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"localFeeData"}
            control={control}
            applyItems={applyItems}
          />
        )}

        {exchangeRateList && applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"freightCost"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"freightFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"freightFeeData"}
            control={control}
            applyItems={applyItems}
          />
        )}

        {exchangeRateList && applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"domesticCost"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"domesticFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"domesticFeeData"}
            control={control}
            applyItems={applyItems}
          />
        )}

        {exchangeRateList && applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"inlandCost"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"inlandFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"inlandFeeData"}
            control={control}
            applyItems={applyItems}
          />
        )}

        {exchangeRateList && applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"otherCost"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"otherFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"otherFeeData"}
            control={control}
            applyItems={applyItems}
          />
        )}

        {exchangeRateList && applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"tax"}
            reset={reset}
            watch={watch}
            templateFeeDataType={"taxFeeData"}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"taxFeeData"}
            control={control}
            applyItems={applyItems}
          />
        )}
      </Grid>

      <QuotationEtcFormList
        freightType={freightType}
        control={control}
        errors={errors}
        linerList={linerList}
      />

      {bidCreateFormData.bidType === "import" && ConfirmQuotationButton}

      {bidCreateFormData.bidType === "export" && ConfirmExportQuotationButton}

      {ReturnToBidCreateButton}

      {ResponseHandlerOfConfirmExportQuotation}
      {ResponseHandlerOfConfirmQuotation}
    </Grid>
  );
}

export default QuotationForm;
