import { useParams } from "react-router-dom";

import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";

import useQuotationData from "pages/bid/_hooks/useQuotationData";

import UserQuotation from "./UserQuotation";

const ChangeUserQuotation = () => {
  const { id }: { id: string } = useParams();

  const {
    bidDetail,
    exchangeRateList,
    applyItems,
    linerList,
    isShipmentDetailFetching,
  } = useQuotationData({
    shipmentId: id,
  });
  if (
    !bidDetail ||
    !exchangeRateList ||
    !applyItems ||
    !linerList ||
    isShipmentDetailFetching
  ) {
    return (
      <Layout title={"견적서 수정"}>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout title={"견적서 수정"}>
      <UserQuotation
        bidDetail={bidDetail}
        exchangeRateList={exchangeRateList}
        applyItems={applyItems}
        linerList={linerList}
      />
    </Layout>
  );
};

export default ChangeUserQuotation;
