import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useAtom, useAtomValue } from "jotai";
import { FORWARDING_ACCOUNT_ATOMS } from "jotaiStates/account";

import { CREATE_ACCOUNT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminAccount";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_ACCOUNT_QUERY, {
  ADMIN_ACCOUNT_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_ACCOUNT_QUERY";
import { AccountFileRef } from "@sellernote/_shared/src/types/forwarding/adminAccount";

import { AccountCategoryState } from "../types";
import { getCategory, validateCreateAccount } from "../utils";

import useGetAccountParams from "./useGetAccountParams";

const useCreateAccount = ({
  newAccountForm,
  category,
  fileRefList,
  onModalClose,
  resetForm,
}: {
  newAccountForm: CREATE_ACCOUNT_REQ;
  category: AccountCategoryState;
  fileRefList: AccountFileRef[];
  onModalClose: () => void;
  resetForm: () => void;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [selectedTeamList, setSelectedTeamList] = useAtom(
    FORWARDING_ACCOUNT_ATOMS.SELECTED_TEAM_LIST
  );

  const [selectedPartnerList, setSelectedPartnerList] = useAtom(
    FORWARDING_ACCOUNT_ATOMS.SELECTED_PARTNER_LIST
  );

  const selectedTeamIdList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.SELECTED_TEAM_ID_LIST
  );

  const selectedPartnerIdList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.SELECTED_PARTNER_ID_LIST
  );

  const hasTeam = Boolean(selectedTeamList.length);
  const hasPartner = Boolean(selectedPartnerList.length);

  /** 거래처 생성 API */
  const {
    mutate: createAccount,
    ResponseHandler: ResponseHandlerOfCreateAccount,
  } = ADMIN_ACCOUNT_QUERY.useCreateAccount();

  const { params } = useGetAccountParams();

  const handleAccountCreate = useCallback(() => {
    const categoryForm = getCategory(category);

    if (!hasTeam && !hasPartner) {
      handleSnackbarOpen(
        "최소 하나의 팀 혹은 파트너를 선택해주세요.",
        "warning"
      );
      return;
    }

    const isValidForm = validateCreateAccount({
      newAccountForm,
      category,
      fileRefList,
      onSnackbarOpen: handleSnackbarOpen,
    });

    if (!isValidForm) return;

    createAccount(
      {
        ...newAccountForm,
        ...categoryForm,
        ...(fileRefList.length ? { fileRefs: fileRefList } : {}),
        ...(hasTeam ? { teams: selectedTeamIdList } : {}),
        ...(hasPartner ? { partners: selectedPartnerIdList } : {}),
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("거래처를 성공적으로 생성했습니다.");

          setSelectedTeamList([]);
          setSelectedPartnerList([]);

          resetForm();
          onModalClose();

          return queryClient.invalidateQueries(
            ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountList(params)
          );
        },
      }
    );
  }, [
    category,
    createAccount,
    fileRefList,
    handleSnackbarOpen,
    hasPartner,
    hasTeam,
    newAccountForm,
    onModalClose,
    params,
    queryClient,
    resetForm,
    selectedPartnerIdList,
    selectedTeamIdList,
    setSelectedPartnerList,
    setSelectedTeamList,
  ]);

  return {
    handleAccountCreate,
    ResponseHandlerOfCreateAccount,
  };
};

export default useCreateAccount;
