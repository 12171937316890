import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Button, Grid, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";

import AutoCompleteWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/AutoCompleteWithReactHookForm";
import SwitchWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/SwitchWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/TextFieldWithReactHookForm";
import { GET_PURCHASE_ORDER_PRODUCTS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { packageOptionList } from "@sellernote/_shared/src/utils/common/options";

import { checkCanUpdateSeparator } from "../utils";

function ProductsInfoForm({
  formIndex,

  productsData,
}: {
  formIndex: number;

  productsData: GET_PURCHASE_ORDER_PRODUCTS_RES | undefined;
}) {
  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const { control, setValue, watch } = useFormContext<BidCreateFormData>();

  const freightType = watch("freightType");

  const productsInfo = useWatch({
    control,
    name: "productsInfo",
  });

  const productName = useWatch({
    control,
    name: `products.${formIndex}.productName`,
  });

  const separator = useWatch({
    control,
    name: `products.${formIndex}.separator`,
  });

  // products 변수명이 중복되기 때문에 PO를 붙임
  const POproducts = useWatch({
    control,
    name: "products",
  });

  /**
   * 사용한 발주 품명을 다른 인풋에서는 제거하기 위한 옵션리스트
   */
  const productsNameOptionList = useMemo(() => {
    const productsNameArray = POproducts?.map(({ productName, ...rest }) => {
      return productName;
    });

    if (productsNameArray) {
      return productsData?.filter((v) => {
        return !productsNameArray.includes(v.productName);
      });
    }

    return productsData;
  }, [POproducts, productsData]);

  const canUpdateSeparator = checkCanUpdateSeparator({
    productsData,
    currentProductName: productName,
  });

  const showsEditButton = useMemo(() => {
    if (productsInfo) {
      if (cargoInfoFormType !== "totalVolume" && productsInfo.length > 1) {
        return true;
      }
      return false;
    }
    return false;
  }, [cargoInfoFormType, productsInfo]);

  const handleRemoveForm = () => {
    const formData = productsInfo?.filter((v, index) => {
      return index !== formIndex;
    });
    setValue("productsInfo", formData);
  };

  return (
    <Grid
      item
      container
      spacing={1}
      xs={12}
      direction={"column"}
      sx={{ marginBottom: "16px" }}
    >
      <Grid item container spacing={1}>
        {freightType === "AIR" && (
          <Grid item xs={2}>
            <SwitchWithReactHookForm
              label="냉동/냉장"
              control={control}
              name={`productsInfo.${formIndex}.needRefrigeration`}
              defaultValue={false}
            />
          </Grid>
        )}

        {freightType === "LCL" && (
          <Grid item xs={2}>
            {productsData && productsData.length > 0 ? (
              <AutoCompleteWithReactHookForm
                name={`productsInfo.${formIndex}.name`}
                control={control}
                label="품명"
                options={
                  productsNameOptionList?.map((v) => {
                    return v.productName;
                  }) ?? []
                }
                handleEffectOnChange={(data) => {
                  if (data && typeof data === "string") {
                    setValue(`products.${formIndex}.productName`, data);
                  }

                  const separator = productsData.find((v) => {
                    return v.productName === data;
                  })?.separator;

                  // 구분값이 존재한다면 폼 상태에 추가
                  if (separator) {
                    setValue(`products.${formIndex}.separator`, separator);
                  }
                  return;
                }}
              />
            ) : (
              <TextFieldWithReactHookForm
                name={`productsInfo.${formIndex}.name`}
                control={control}
                label="품명"
              />
            )}
          </Grid>
        )}

        {freightType === "LCL" && productsData && productsData.length > 0 && (
          <Grid item xs={2}>
            <TextFieldWithReactHookForm
              name={`products.${formIndex}.separator`}
              control={control}
              label="구분자"
              disabled={!canUpdateSeparator}
              handleEffectOnChange={(e) => {
                // API 요청 시 빈스트링인 경우 에러를 냄
                if (!e?.target.value) {
                  setValue(`products.${formIndex}.separator`, undefined);
                }
              }}
            />
          </Grid>
        )}

        <Grid item>
          <SwitchWithReactHookForm
            label="위험물"
            control={control}
            name={`productsInfo.${formIndex}.isDangerous`}
          />
        </Grid>

        <Grid item>
          <SwitchWithReactHookForm
            label="2단적재"
            control={control}
            name={`productsInfo.${formIndex}.isDouble`}
          />
        </Grid>
      </Grid>

      {freightType === "AIR" && (
        <Grid item xs={2}>
          {productsData && productsData.length > 0 ? (
            <AutoCompleteWithReactHookForm
              name={`productsInfo.${formIndex}.name`}
              control={control}
              label="품명"
              options={
                productsNameOptionList?.map((v) => {
                  return v.productName;
                }) ?? []
              }
              handleEffectOnChange={(data) => {
                if (data && typeof data === "string") {
                  setValue(`products.${formIndex}.productName`, data);
                }

                const separator = productsData.find((v) => {
                  return v.productName === data;
                })?.separator;

                // 구분값이 존재한다면 폼 상태에 추가
                if (separator) {
                  setValue(`products.${formIndex}.separator`, separator);
                }
                return;
              }}
            />
          ) : (
            <Grid item xs={2}>
              <TextFieldWithReactHookForm
                name={`productsInfo.${formIndex}.name`}
                control={control}
                label="품명"
              />
            </Grid>
          )}
        </Grid>
      )}

      {freightType === "AIR" && productsData && productsData.length > 0 && (
        <Grid item xs={2}>
          <TextFieldWithReactHookForm
            name={`products.${formIndex}.separator`}
            control={control}
            label="구분"
            disabled={!canUpdateSeparator}
            handleEffectOnChange={(e) => {
              // API 요청 시 빈스트링인 경우 에러를 냄
              if (!e?.target.value) {
                setValue(`products.${formIndex}.separator`, undefined);
              }
            }}
          />
        </Grid>
      )}

      <Grid item container spacing={1} alignItems="flex-end">
        <Grid item xs={2}>
          <AutoCompleteWithReactHookForm
            name={`productsInfo.${formIndex}.packingType`}
            control={control}
            label="포장 타입"
            options={packageOptionList.map((v) => {
              return v.value;
            })}
          />
        </Grid>

        <Grid item>
          <TextFieldWithReactHookForm
            name={`productsInfo.${formIndex}.quantity`}
            control={control}
            type="number"
            label="수량"
            isOnlyPositiveNumber={true}
          />
        </Grid>
      </Grid>

      {cargoInfoFormType === "totalVolume" && (
        <Grid item container spacing={1} alignItems="flex-end">
          <Grid item>
            <TextFieldWithReactHookForm
              name={`productsInfo.${formIndex}.cbm`}
              control={control}
              label="부피"
              type="number"
              isOnlyPositiveNumber={true}
            />
          </Grid>

          <Grid item>
            <Typography variant="body2">CBM</Typography>
          </Grid>
        </Grid>
      )}

      {cargoInfoFormType === "packaging" && (
        <Grid item container spacing={1} alignItems="flex-end">
          <Grid item>
            <TextFieldWithReactHookForm
              name={`productsInfo.${formIndex}.horizontal`}
              control={control}
              label="가로"
              type="number"
              isOnlyPositiveNumber={true}
            />
          </Grid>

          <Grid item>
            <TextFieldWithReactHookForm
              name={`productsInfo.${formIndex}.vertical`}
              control={control}
              label="세로"
              type="number"
              isOnlyPositiveNumber={true}
            />
          </Grid>

          <Grid item>
            <TextFieldWithReactHookForm
              name={`productsInfo.${formIndex}.height`}
              control={control}
              label="높이"
              type="number"
              isOnlyPositiveNumber={true}
            />
          </Grid>

          <Grid item>
            <AutoCompleteWithReactHookForm
              name={`productsInfo.${formIndex}.volumeUnit`}
              control={control}
              label="부피 단위"
              options={["CM", "M"]}
            />
          </Grid>
        </Grid>
      )}

      <Grid item container spacing={1} alignItems="flex-end">
        <Grid item>
          <TextFieldWithReactHookForm
            name={`productsInfo.${formIndex}.weight`}
            control={control}
            label="중량"
            type="number"
            isOnlyPositiveNumber={true}
          />
        </Grid>

        <Grid item xs={2}>
          <AutoCompleteWithReactHookForm
            name={`productsInfo.${formIndex}.weightUnit`}
            control={control}
            freeSolo={false}
            label="중량단위"
            options={["KG", "TON"]}
          />
        </Grid>
      </Grid>

      {showsEditButton && (
        <Grid item>
          <Button color="error" onClick={handleRemoveForm}>
            삭제
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default ProductsInfoForm;
