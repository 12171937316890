import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

import AdminUserTable from "./AdminUserTable";

const AdminUser = () => {
  const { data: adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  return (
    <Layout>
      <AdminUserTable adminUserList={adminUserList} />
    </Layout>
  );
};

export default AdminUser;
