import { useCallback, useState } from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { useFormContext, useWatch } from "react-hook-form";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import AutoCompleteWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/AutoCompleteWithReactHookForm";
import RadioGroupWithReactHookForm, {
  RadioGroupWithReactHookFormOption,
} from "@sellernote/_shared/src/admin-ui/components/RadioGroupWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/TextFieldWithReactHookForm";
import { ADMIN_BID_WAREHOUSE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import { Port } from "@sellernote/_shared/src/types/common/common";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { BidTransportType } from "@sellernote/_shared/src/types/forwarding/bid";

import { getAutoCompleteValidate, getPortNameOptionList } from "../utils";

function ConsolidationInfo({
  syncZoneId,
  endTypeRadioGroupOption,
  portList = [],
  bidDetail,
}: {
  syncZoneId: (address?: string | null) => void;
  endTypeRadioGroupOption: RadioGroupWithReactHookFormOption<BidTransportType>[];
  portList?: Port[];
  bidDetail: AdminBidDetail;
}) {
  const [opensDaumPostcode, setOpensDaumPostcode] = useState(false);

  const { control, setValue, clearErrors } = useFormContext();

  const endType = useWatch({ control, name: "endType" });

  const endAddress = useWatch({ control, name: "endAddress" });

  const {
    freightType,
    endType: shipmentEndType,
    endPortId: shipmentEndPortId,
    endViaPortId: shipmentEndViaPortId,
    zoneId: shipmentZoneId,
    endAddress: shipmentEndAddress,
    endAddressDetail: shipmentEndAddressDetail,
    inlandType: shipmentInlandType,
  } = bidDetail;

  const TransportInfoRef = useCallback(
    (node) => {
      if (node) {
        syncZoneId(endAddress);
      }
    },
    [endAddress, syncZoneId]
  );

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}
      ref={TransportInfoRef}
    >
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        <Typography variant="subtitle1" fontWeight={700}>
          창고명
        </Typography>

        <AutoCompleteWithReactHookForm
          name="wareHouseId"
          control={control}
          options={ADMIN_BID_WAREHOUSE_OPTION_LIST}
          required={true}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
        <Typography variant="subtitle1" fontWeight={700}>
          도착지
        </Typography>

        <RadioGroupWithReactHookForm
          name="endType"
          control={control}
          required={true}
          radioGroupOption={endTypeRadioGroupOption}
          handleEffectOnChange={(e) => {
            const isInlandShipment = shipmentEndType === "inland";

            if (e.target.value === "inland") {
              setValue("endPortId", null, {
                shouldDirty: isInlandShipment,
              });
              setValue(
                "endViaPortId",
                shipmentEndViaPortId || shipmentEndPortId,
                {
                  shouldDirty: !isInlandShipment,
                }
              );
              setValue("endAddress", shipmentEndAddress, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endAddressDetail", shipmentEndAddressDetail, {
                shouldDirty: !isInlandShipment,
              });
              setValue("zoneId", shipmentZoneId, {
                shouldDirty: !isInlandShipment,
              });
              setValue("inlandType", shipmentInlandType || "sole", {
                shouldDirty: !isInlandShipment,
              });
            } else {
              setValue("endPortId", shipmentEndPortId, {
                shouldDirty: isInlandShipment,
              });
              setValue("endViaPortId", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endAddress", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("endAddressDetail", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("zoneId", null, {
                shouldDirty: !isInlandShipment,
              });
              setValue("inlandType", null, {
                shouldDirty: !isInlandShipment,
              });
            }
          }}
        />

        {endType === "inland" && (
          <Box sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}>
            <AutoCompleteWithReactHookForm
              name="endViaPortId"
              control={control}
              label="항구/공항 선택"
              options={getPortNameOptionList({
                transportType: endType,
                portList: portList,
                freightType,
                isExport: false,
                isEndPort: true,
              })}
              required={true}
              disabled
              validate={{ isSelect: (value) => getAutoCompleteValidate(value) }}
            />

            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}
            >
              <TextFieldWithReactHookForm
                name="endAddress"
                control={control}
                label="도착지 주소"
                placeholder="도착지 주소"
                InputProps={{
                  readOnly: true,
                  endAdornment: endAddress && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setValue("endAddress", "", { shouldDirty: true });
                          setValue("endType", "sea", { shouldDirty: true });
                        }}
                      >
                        <CloseIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ flexGrow: 1 }}
              />

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpensDaumPostcode(!opensDaumPostcode)}
              >
                주소검색 {opensDaumPostcode && "닫기"}
              </Button>
            </Box>

            {opensDaumPostcode && (
              <DaumPostcodeEmbed
                showMoreHName={true}
                onComplete={({ address }) => {
                  setValue("endAddress", address, { shouldDirty: true });
                  clearErrors("endAddress");
                  setOpensDaumPostcode(false);
                }}
              />
            )}

            <TextFieldWithReactHookForm
              name="endAddressDetail"
              control={control}
              label="상세주소"
              placeholder="상세주소"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ConsolidationInfo;
