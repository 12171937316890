import { useHistory, useParams } from "react-router-dom";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import { Box, Button } from "@mui/material";

import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

import AdminForm from "./AdminForm";

const AdminUserDetail = () => {
  const { id }: { id: string } = useParams();

  const history = useHistory();

  const { data: adminUserDetail, isFetched } =
    ADMIN_USER_QUERY.useGetAdminUserDetail({
      adminId: Number(id),
    });

  return (
    <Layout breadcrumbs={["사용자 관리"]} title={"사용자 수정"}>
      <div style={{ maxWidth: "400px" }}>
        <Button onClick={() => history.go(-1)} variant="outlined">
          <FormatAlignLeftIcon />
          목록
        </Button>

        <Box sx={{ mt: 4 }}>
          {isFetched && <AdminForm data={adminUserDetail} id={id} />}
        </Box>
      </div>
    </Layout>
  );
};

export default AdminUserDetail;
