import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useAtomValue } from "jotai";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared/src/jotaiStates/auth";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";

import FreightPaymentType from "./FreightPaymentType";
import PartnerList from "./PartnerList";

const PartnerDesc = ({
  bidDetail,
  hasExporterInfo,
}: {
  bidDetail: AdminBidDetail;
  hasExporterInfo: boolean;
}) => {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const canEdit: boolean = useMemo(() => {
    if (bidDetail.projectStatus === "settlementComplete") {
      if (currentAdminAuthInfo?.authority !== "master") {
        return false;
      }

      return true;
    }

    return true;
  }, [bidDetail.projectStatus, currentAdminAuthInfo?.authority]);

  return (
    <Box>
      <Typography variant="h6">파트너 정보</Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 8,
        }}
      >
        <FreightPaymentType
          canEdit={canEdit}
          quotationsUser={bidDetail.quotationsUser}
          bidId={bidDetail.id}
        />

        <PartnerList
          bidDetail={bidDetail}
          canEdit={canEdit}
          hasExporterInfo={hasExporterInfo}
        />
      </Box>
    </Box>
  );
};

export default PartnerDesc;
