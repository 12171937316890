import { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAtom } from "jotai";
import { FORWARDING_ACCOUNT_ATOMS } from "jotaiStates/account";

import TextField from "@sellernote/_shared/src/admin-ui/components/TextField";

export default function SearchOption() {
  const [option, setOption] = useState<string | undefined>(undefined);
  const [inputValue, setInputValue] = useState("");

  const [filter, setFilter] = useAtom(
    FORWARDING_ACCOUNT_ATOMS.ACCOUNT_TABLE_FILTER
  );

  const handleOptionUpdate = (value: string) => {
    if (!value) {
      setInputValue("");

      setFilter({
        ...filter,
        searchOption: {},
      });
    }

    setOption(value);
  };

  const handleFilterUpdate = (value: string) => {
    setInputValue(value);

    if (!option) return;

    setFilter({
      ...filter,
      page: 0,
      searchOption: {
        [option]: value,
      },
    });
  };

  return (
    <>
      <FormControl size="small">
        <InputLabel>검색대상</InputLabel>

        <Select
          value={option}
          label="검색대상"
          autoWidth
          sx={{ minWidth: 120 }}
          onChange={(e) => handleOptionUpdate(e.target.value)}
        >
          <MenuItem value={undefined}>전체</MenuItem>
          <MenuItem value={"name"}>거래처명</MenuItem>
          <MenuItem value={"BRN"}>사업자등록번호</MenuItem>
          <MenuItem value={"representativeName"}>대표자 성명</MenuItem>
          <MenuItem value={"teamName"}>팀명</MenuItem>
          <MenuItem value={"partnerName"}>파트너명</MenuItem>
        </Select>
      </FormControl>

      <TextField
        variant="outlined"
        size="small"
        sx={{
          width: 150,
        }}
        value={inputValue}
        onChange={(e) => handleFilterUpdate(e.target.value)}
      />
    </>
  );
}
