import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAtom } from "jotai";
import { FORWARDING_ACCOUNT_ATOMS } from "jotaiStates/account";

import { AccountBusinessArea } from "@sellernote/_shared/src/types/forwarding/adminAccount";

export default function Categorization() {
  const [filter, setFilter] = useAtom(
    FORWARDING_ACCOUNT_ATOMS.ACCOUNT_TABLE_FILTER
  );

  const handleChangeCategory = (
    businessArea: AccountBusinessArea | undefined
  ) => {
    setFilter({ ...filter, page: 0, businessArea });
  };

  return (
    <FormControl size="small">
      <InputLabel>구분</InputLabel>

      <Select
        value={filter.businessArea}
        label="구분"
        onChange={(e) => {
          handleChangeCategory(
            e.target.value as AccountBusinessArea | undefined
          );
        }}
        sx={{ width: 150 }}
      >
        <MenuItem value={undefined}>전체</MenuItem>
        <MenuItem value={"domestic"}>국내</MenuItem>
        <MenuItem value={"foreign"}>해외</MenuItem>
      </Select>
    </FormControl>
  );
}
