import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAtom } from "jotai";
import { FORWARDING_ACCOUNT_ATOMS } from "jotaiStates/account";

import { AccountPaymentCondition } from "@sellernote/_shared/src/types/forwarding/adminAccount";

export default function PaymentCondition() {
  const [filter, setFilter] = useAtom(
    FORWARDING_ACCOUNT_ATOMS.ACCOUNT_TABLE_FILTER
  );

  const handleChangePaymentCondition = (
    paymentCondition: AccountPaymentCondition | undefined
  ) => {
    setFilter({ ...filter, page: 0, paymentCondition });
  };

  return (
    <FormControl size="small">
      <InputLabel>지급조건</InputLabel>

      <Select
        value={filter.paymentCondition}
        label="지급조건"
        onChange={(e) =>
          handleChangePaymentCondition(
            e.target.value as AccountPaymentCondition | undefined
          )
        }
        sx={{ width: 150 }}
      >
        <MenuItem value={undefined}>전체</MenuItem>
        <MenuItem value={"monthly"}>월 정산</MenuItem>
        <MenuItem value={"daily"}>일 정산</MenuItem>
      </Select>
    </FormControl>
  );
}
