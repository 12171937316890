import { useMemo } from "react";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";

import { useBidPartnerContext } from "../../../_hooks/useBidPartnerContext";
import ConsolidationsItemEmailForm from "./ConsolidationsItemEmailForm";
import ContainersInfoEmailForm from "./ContainersInfoEmailForm";
import ProductsInfoEmailForm from "./ProductsInfoEmailForm";

export default function RequestEmailToPartner() {
  const {
    bidDetail,
    showsRequestEmailToPartner,
    setShowsRequestEmailToPartner,
  } = useBidPartnerContext();

  const RequestEmailFormToPartner = useMemo(() => {
    if (bidDetail.serviceType === "consolidation") {
      return <ConsolidationsItemEmailForm />;
    }

    if (bidDetail.freightType === "FCL") {
      return <ContainersInfoEmailForm />;
    }

    return <ProductsInfoEmailForm />;
  }, [bidDetail]);

  return (
    <Modal
      isOpened={showsRequestEmailToPartner}
      handleClose={() => setShowsRequestEmailToPartner(false)}
      modalBody={RequestEmailFormToPartner}
      modalBodySx={{
        maxWidth: "600px",
      }}
    />
  );
}
