import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AlignLeftOutlined, SaveOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Input, Typography } from "antd";

import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import { sendRequest } from "@sellernote/_shared/src/services/request";

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const { Text } = Typography;

const AdminUserRegister = () => {
  const { handleSnackbarOpen } = useSnackbar();

  const [adminAuthority, setAdminAuthority] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminEnglishName, setAdminEnglishName] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminId, setAdminId] = useState("");

  const history = useHistory();

  const createAdmin = async () => {
    if (!adminEnglishName) {
      handleSnackbarOpen("영문 이름을 입력해주세요.", "error");
      return;
    }

    try {
      await sendRequest({
        method: "post",
        path: `/auth/admins`,
        apiType: "SHIPDA_ADMIN_API",
        data: {
          accountId: adminId,
          name: adminName,
          nameEN: adminEnglishName,
          email: adminEmail,
          authority: adminAuthority,
          password: adminPassword,
        },
      });

      history.go(-1);
    } catch (err) {
      handleSnackbarOpen("요청에 실패했습니다.", "error");
    }
  };

  const onSelectAuthority = (value: string) => {
    let authorityName = "";

    if (value === "관리자") {
      authorityName = "master";
    } else if (value === "매니저") {
      authorityName = "manager";
    } else if (value === "정산 담당자") {
      authorityName = "finance";
    }

    setAdminAuthority(authorityName);
  };

  const handleChangeAdminName = (e: ChangeEvent) => {
    setAdminName(e.target.value);
  };

  const handleChangeAdminEnglishName = (e: ChangeEvent) => {
    setAdminEnglishName(e.target.value);
  };

  const handleChangeAdminPassword = (e: ChangeEvent) => {
    setAdminPassword(e.target.value);
  };

  const authority = [
    { value: "관리자" },
    { value: "매니저" },
    { value: "정산 담당자" },
  ];

  return (
    <Layout breadcrumbs={["사용자 관리"]} title={"사용자 추가"}>
      <Button onClick={() => history.go(-1)} style={{ marginLeft: "20px" }}>
        <AlignLeftOutlined />
        목록
      </Button>

      <div style={{ padding: "20px" }}>
        <Text strong>권한</Text>

        <br />

        <AutoComplete
          style={{
            width: "300px",
            marginTop: "5px",
          }}
          placeholder="권한"
          onSelect={onSelectAuthority}
          dataSource={authority}
        />

        <br />
        <br />

        <Text strong>아이디</Text>

        <br />

        <Input
          style={{ width: "300px", marginTop: "5px" }}
          placeholder="아이디"
          onChange={(e) => setAdminId(e.target.value)}
          allowClear
        />

        <br />
        <br />

        <Text strong>이름</Text>

        <br />

        <Input
          style={{ width: "300px", marginTop: "5px" }}
          placeholder="이름"
          onChange={handleChangeAdminName}
          allowClear
        />

        <br />
        <br />

        <Text strong>영문 이름</Text>

        <br />

        <Input
          style={{ width: "300px", marginTop: "5px" }}
          placeholder="영문 이름"
          onChange={handleChangeAdminEnglishName}
          allowClear
        />

        <br />
        <br />

        <Text strong>이메일</Text>

        <br />

        <Input
          style={{ width: "300px", marginTop: "5px" }}
          placeholder="이메일"
          onChange={(e) => setAdminEmail(e.target.value)}
          allowClear
        />

        <br />
        <br />

        <Text strong>비밀번호</Text>

        <br />

        <Input
          type="password"
          style={{ width: "300px", marginTop: "5px" }}
          placeholder="비밀번호"
          onChange={handleChangeAdminPassword}
          allowClear
        />

        <br />
        <br />

        <Button onClick={createAdmin} type="primary">
          <SaveOutlined /> 생성
        </Button>
      </div>
    </Layout>
  );
};

export default AdminUserRegister;
